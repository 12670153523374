<template>
  <div class="page-content z-index-10">
    <div class="container text-center text-dark">
      <div class="display-1 error-display mb-5">404</div>
      <h1 class="h2 mb-3">Sayfa Bulunamadı</h1>
      <p class="h4 font-weight-normal mb-7 leading-normal">
        Sayfaya şuanda erişilemiyor, lütfen sonra tekrar deneyin.
      </p>
      <router-link class="btn btn-secondary" :to="'/'">
        Ana Sayfaya Dön
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
  components: {},
  created() {
    document.title = "404 Hatası";
    this.$store.state.header = false;
    this.$store.state.footer = false;
  },
};
</script>
